import { sk, ru, pl, nl, hu, fr, fi, es, en, da, de } from 'vuetify/locale'

export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'EN',
  messages: {
    DK: {
      $vuetify: da,
    },
    DU: {
      $vuetify: de,
    },
    EN: {
      $vuetify: en,
    },
    ES: {
      $vuetify: es,
    },
    FI: {
      $vuetify: fi,
    },
    FR: {
      $vuetify: fr,
    },
    HU: {
      $vuetify: hu,
    },
    NL: {
      $vuetify: nl,
    },
    PL: {
      $vuetify: pl,
    },
    RU: {
      $vuetify: ru,
    },
    SK: {
      $vuetify: sk,
    },
  },
}))
